import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer'>
            {/* <p>Created by Phil Hurst & Schwyn Francis</p> */}
        </div>
    )
}

export default Footer;